var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card-header',[_c('h2',[_c('feather-icon',{attrs:{"icon":"LayersIcon"}}),_vm._v(" "+_vm._s(_vm.accountBankData.proveedorTiCuentaBancariaId == null ? _vm.$t('Routes.AddBank') : _vm.$t('Routes.EditBank'))+" ")],1)]),_c('b-card-body',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppTI.filters.provider'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTI.filters.provider')}},[_c('v-select',{attrs:{"id":"proveedores","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"texto","options":_vm.providers,"reduce":function (option) { return option.id; }},model:{value:(_vm.accountBankData.proveedorTiId),callback:function ($$v) {_vm.$set(_vm.accountBankData, "proveedorTiId", $$v)},expression:"accountBankData.proveedorTiId"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppTI.filters.bank'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTI.filters.bank')}},[_c('v-select',{attrs:{"id":"bancos","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"texto","options":_vm.banks,"reduce":function (option) { return option.id; }},model:{value:(_vm.accountBankData.bancoId),callback:function ($$v) {_vm.$set(_vm.accountBankData, "bancoId", $$v)},expression:"accountBankData.bancoId"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTI.accountBank.count'),"state":errors.length > 0 ? false : null}},[_c('b-form-input',{model:{value:(_vm.accountBankData.noCuenta),callback:function ($$v) {_vm.$set(_vm.accountBankData, "noCuenta", $$v)},expression:"accountBankData.noCuenta"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTI.accountBank.clabe'),"state":errors.length > 0 ? false : null}},[_c('b-form-input',{model:{value:(_vm.accountBankData.clabe),callback:function ($$v) {_vm.$set(_vm.accountBankData, "clabe", $$v)},expression:"accountBankData.clabe"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTI.accountBank.card'),"state":errors.length > 0 ? false : null}},[_c('b-form-input',{model:{value:(_vm.accountBankData.tarjeta),callback:function ($$v) {_vm.$set(_vm.accountBankData, "tarjeta", $$v)},expression:"accountBankData.tarjeta"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required|max:1025"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTI.accountBank.businessName'),"state":errors.length > 0 ? false : null}},[_c('b-form-input',{model:{value:(_vm.accountBankData.razonSocial),callback:function ($$v) {_vm.$set(_vm.accountBankData, "razonSocial", $$v)},expression:"accountBankData.razonSocial"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppTI.accountBank.rfc'),"rules":"required|max:13|min:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTI.accountBank.rfc'),"state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"onkeyup":"this.value = this.value.toUpperCase();"},model:{value:(_vm.accountBankData.rfc),callback:function ($$v) {_vm.$set(_vm.accountBankData, "rfc", $$v)},expression:"accountBankData.rfc"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTI.accountBank.cp'),"state":errors.length > 0 ? false : null}},[_c('b-form-input',{model:{value:(_vm.accountBankData.cp),callback:function ($$v) {_vm.$set(_vm.accountBankData, "cp", $$v)},expression:"accountBankData.cp"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"float-right mt-2 mb-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.accountBankData.proveedorTiCuentaBancariaId == null ? _vm.$t("Lists.Add") : _vm.$t("Lists.Edit"))+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }