<template>
  <b-card>
    <b-card-header>
      <h2>
        <feather-icon icon="LayersIcon" />
        {{ accountBankData.proveedorTiCuentaBancariaId == null ? $t('Routes.AddBank') : $t('Routes.EditBank') }}
      </h2>
    </b-card-header>
    <b-card-body>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="12" md="6">
              <validation-provider #default="{ errors }" :name="$t('AppTI.filters.provider')" rules="required">
                <b-form-group :label="$t('AppTI.filters.provider')">
                  <v-select
                    id="proveedores"
                    v-model="accountBankData.proveedorTiId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="texto"
                    :options="providers"
                    :reduce="option => option.id"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider #default="{ errors }" :name="$t('AppTI.filters.bank')" rules="required">
                <b-form-group :label="$t('AppTI.filters.bank')">
                  <v-select
                    id="bancos"
                    v-model="accountBankData.bancoId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="texto"
                    :options="banks"
                    :reduce="option => option.id"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="{ errors }"
                name="nombre"
                rules="max:20"
              >
                <b-form-group
                  :label="$t('AppTI.accountBank.count')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input v-model="accountBankData.noCuenta" />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="{ errors }"
                name="nombre"
                rules="max:50"
              >
                <b-form-group
                  :label="$t('AppTI.accountBank.clabe')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input v-model="accountBankData.clabe" />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="{ errors }"
                name="nombre"
                rules="max:16"
              >
                <b-form-group
                  :label="$t('AppTI.accountBank.card')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input v-model="accountBankData.tarjeta" />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="{ errors }"
                name="nombre"
                rules="required|max:1025"
              >
                <b-form-group
                  :label="$t('AppTI.accountBank.businessName')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input v-model="accountBankData.razonSocial" />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="{ errors }"
                :name="$t('AppTI.accountBank.rfc')"
                rules="required|max:13|min:12"
              >
                <b-form-group
                  :label="$t('AppTI.accountBank.rfc')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    v-model="accountBankData.rfc"
                    onkeyup="this.value = this.value.toUpperCase();"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="{ errors }"
                name="nombre"
                rules="required|max:10"
              >
                <b-form-group
                  :label="$t('AppTI.accountBank.cp')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input v-model="accountBankData.cp" />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="float-right mt-2 mb-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ accountBankData.proveedorTiCuentaBancariaId == null ? $t("Lists.Add") : $t("Lists.Edit") }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BCol,
} from 'bootstrap-vue'
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import { required, max, min } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import tiServiceService from '@/services/informationTecnology.service'
import router from '@/router'
import vSelect from 'vue-select'
import optionService from '@/services/option.service'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    ValidationObserver,
    BForm,
    BFormGroup,
    BButton,
    BFormInput,
    BFormInvalidFeedback,
    BRow,
    BCol,
    ValidationProvider,
    vSelect,
  },
  setup() {
    const providers = ref([])
    const banks = ref([])
    const accountBankData = ref({
      proveedorTiCuentaBancariaId: null,
      proveedorTiId: null,
      bancoId: null,
      noCuenta: '',
      clabe: '',
      tarjeta: '',
      razonSocial: '',
      rfc: '',
      cp: '',
    })
    const blankAccountBank = {
      proveedorTiCuentaBancariaId: null,
      proveedorTiId: null,
      bancoId: null,
      noCuenta: '',
      clabe: '',
      tarjeta: '',
      razonSocial: '',
      rfc: '',
      cp: '',
    }

    const resetAccountBankankData = () => {
      accountBankData.value = JSON.parse(JSON.stringify(blankAccountBank))
    }

    const {
      fetchAccountBankProvider,
      updateAccountBankProvider,
      createAccountBankProvider,
    } = tiServiceService()

    const { fetchOptions } = optionService()

    fetchOptions({ nombreDominio: 'proveedoresTi' }, data => {
      providers.value = data
    })
    fetchOptions({ nombreDominio: 'Bancos' }, data => {
      banks.value = data
    })

    if (router.currentRoute.params.proveedorTiCuentaBancariaId) {
      fetchAccountBankProvider(router.currentRoute.params.proveedorTiCuentaBancariaId, data => {
        accountBankData.value = data
      })
    } else {
      accountBankData.value = JSON.parse(JSON.stringify(blankAccountBank))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetAccountBankankData)

    const onSubmit = () => {
      if (accountBankData.value.proveedorTiCuentaBancariaId === null) {
        createAccountBankProvider(accountBankData.value, () => {
          router.push({ name: 'apps-account-bank-list' })
        })
      } else {
        updateAccountBankProvider(accountBankData.value.proveedorTiCuentaBancariaId, accountBankData.value, () => {
          router.push({ name: 'apps-account-bank-list' })
        })
      }
    }

    return {
      providers,
      banks,
      accountBankData,
      required,
      max,
      min,
      getValidationState,
      refFormObserver,
      resetForm,
      onSubmit,
    }
  },
}

</script>
